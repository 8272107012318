<template>
  <v-container fluid>
    <v-card>
      <FormToolbar title="Акції">
        <template v-slot:left>
          <BackButton />
          <v-tabs v-model="currentTab">
            <v-tab v-for="tab in tabs" :key="tab.title">
              {{ tab.title }}
            </v-tab>
          </v-tabs>
          <v-spacer></v-spacer>
        </template>
        <template v-slot:right>
          <PromotionDialog
            :title="dialogTitle"
            :is-opened="dialog"
            :is-editing="editedIndex > -1"
            :edited-item="editedItem"
            @yes="save"
            @no="close"
          />
          <DeleteDialog :is-opened="dialogDelete" message="Акцію буде видалено" @yes="deletePromotionConfirm" @no="closeDelete" />
        </template>
      </FormToolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              :is="item.component"
              :loading="loading"
              @edit="editItem"
              @delete="deleteItem"
              @paginate="paginateTo"
            ></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import CircleIndicator from '@/components/common/CircleIndicator'
import FormToolbar from '@/components/common/forms/FormToolbar'
import { PROMOTION_SCHEMA } from '@/const/apiSchemas'
import BackButton from '@/components/common/BackButton.vue'
import { mapActions, mapState } from 'vuex'
import forceRerender from '@/mixins/forceRerender'

export default {
  name: 'PromotionsList',

  components: {
    BackButton,
    CircleIndicator,
    FormToolbar,
    PromotionDialog: () => import('@/components/marketing/promotions/PromotionDialog'),
    DeleteDialog: () => import('@/components/dialogs/DeleteDialog'),
    PromotionsListLocal: () => import('@/components/marketing/promotions/PromotionsListLocal'),
    PromotionsListGlobal: () => import('@/components/marketing/promotions/PromotionsListGlobal'),
  },

  mixins: [forceRerender],

  data() {
    return {
      currentTab: 0,
      tabs: [
        {
          title: 'Акції компанії',
          component: 'PromotionsListLocal',
        },
        {
          title: 'Глобальні акції',
          component: 'PromotionsListGlobal',
        },
      ],
      editedIndex: -1,
      editedItem: {},
      dialog: false,
      dialogTitle: 'Створення акції компанії',
      dialogDelete: false,
      loading: false,
      localPayload: {},
      globalPayload: {},
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete()
    },
    currentTab(val) {
      this.dialogTitle = val === 0 ? 'Створення акції компанії' : 'Створення глобальної акції'
    },
  },

  computed: {
    ...mapState('promotions', [
      'promotions',
      'promotionsMeta',
      'globalPromotions',
      'globalPromotionsMeta',
      'promotion',
      'globalPromotion',
    ]),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  methods: {
    ...mapActions('promotions', [
      'loadPromotions',
      'createPromotion',
      'updateSelectedPromotion',
      'deleteSelectedPromotion',
      'loadGlobalPromotions',
      'createGlobalPromotion',
      'updateSelectedGlobalPromotion',
      'deleteSelectedGlobalPromotion',
      'loadSelectedPromotion',
      'loadSelectedGlobalPromotion',
    ]),

    async paginateTo(payload) {
      this.loading = true
      try {
        if (this.currentTab === 0) {
          this.localPayload = payload
          await this.loadPromotions(this.localPayload)
        } else {
          this.globalPayload = payload
          await this.loadGlobalPromotions(this.globalPayload)
        }
      } finally {
        this.loading = false
      }
    },

    async editItem(item) {
      if (this.currentTab === 0) {
        this.editedIndex = this.promotions.indexOf(item)
        const payload = {
          companyId: this.currentCompanyId,
          promotionId: item.id,
        }
        await this.loadSelectedPromotion(payload)
      } else {
        this.editedIndex = this.globalPromotions.indexOf(item)
        await this.loadSelectedGlobalPromotion({ promotionId: item.id })
      }
      this.editedItem = this.currentTab === 0 ? CloneDeep(this.promotion) : CloneDeep(this.globalPromotion)
      this.clickedPromotionId = item.id
      this.forceRerender()
      this.dialogTitle = `Редагування ${this.currentTab === 0 ? 'акції компанії' : 'глобальної акції'}`
      this.dialog = true
    },

    close() {
      this.editedIndex = -1
      this.editedItem = CloneDeep(PROMOTION_SCHEMA)
      this.dialog = false
    },

    async save(payload) {
      this.loading = true
      try {
        if (this.editedIndex === -1) {
          this.currentTab === 0
            ? await this.createPromotion({
                companyId: this.currentCompanyId,
                newPromotion: payload,
              })
            : await this.createGlobalPromotion({ newPromotion: payload })
        } else {
          this.currentTab === 0
            ? await this.updateSelectedPromotion({
                companyId: this.currentCompanyId,
                id: this.clickedPromotionId,
                updatedPromotion: payload,
              })
            : await this.updateSelectedGlobalPromotion({
                id: this.clickedPromotionId,
                updatedPromotion: payload,
              })
        }
      } finally {
        this.loading = false
        this.close()
        await this.paginateTo(this.currentTab === 0 ? this.localPayload : this.globalPayload)
      }
    },

    deleteItem(id) {
      this.clickedPromotionId = id
      this.dialogDelete = true
    },

    async deletePromotionConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        id: this.clickedPromotionId,
      }
      try {
        this.currentTab === 0 ? await this.deleteSelectedPromotion(payload) : await this.deleteSelectedGlobalPromotion(payload)
      } finally {
        this.loading = false
        this.closeDelete()
        await this.paginateTo(this.currentTab === 0 ? this.localPayload : this.globalPayload)
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.clickedPromotionId = null
      this.editedIndex = -1
    },
  },
}
</script>
